@import "@/assets/css/styles.less";

.subbrand-select {
  max-width: 200px;
  margin-top: 15px !important;
}
.headers {
  color: #22313f;
  font-size: 1rem;
}
