@import "@/assets/css/styles.less";

body {
  font-family: "Montserrat", sans-serif;
}

p h3 h4 h5 {
  font-size: 1.2rem;
  font-weight: 100;
}

* {
  /* https://stackoverflow.com/a/38977324/4562156 */
  -webkit-overflow-scrolling: touch;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (-o-min-device-pixel-ratio: 3/2),
  only screen and (min--moz-device-pixel-ratio: 1.5),
  only screen and (min-device-pixel-ratio: 1.5) {
  html,
  body {
    width: 100%;
    height: 100%;
  }
}

.text-150p {
  font-size: 150%;
}
.text-center {
  text-align: center;
}

.text-gridlite-gold {
  color: #f4da32 !important;
}

.text-gridlite-blue {
  color: #2196f3 !important;
}

.text-gridlite-red {
  color: #f43266 !important;
}

.dark-bg {
  background-color: #212121;
}

.gold-bg {
  background-color: #f4da32 !important;
}

.blue-bg {
  background-color: #2196f3 !important;
}

.red-bg {
  background-color: #f43266 !important;
}

.white-bg {
  background-color: #f1f1f1 !important;
}

.light-stroke {
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}

.light-stroke-gold {
  text-shadow: -1px -1px 0 rgb(244, 218, 50), 1px -1px 0 rgb(244, 218, 50),
    -1px 1px 0 rgb(244, 218, 50), 1px 1px 0 rgb(244, 218, 50);
}

.gridlite-logo {
  font-family: "Noto Sans", sans-serif;
}

.gridlite-title {
  font-family: "Noto Sans", sans-serif !important;
  font-weight: 800 !important;
  font-size: 3rem !important;
}

.h-100 {
  height: 100% !important;
}

.btn-circle {
  border-radius: 50% !important;
}

.padded-section {
  padding-top: 3rem;
}

.app-max-width {
  max-width: 1200px !important;
}

.rgba-black-medium {
  background-color: rgba(0, 0, 0, 0.34);
}
.v-btn {
  border-radius: 5px !important;
}
.round-top {
  border-radius: 10px 10px 0 0 !important;
}
.round-full {
  border-radius: 10px 10px 10px 10px !important;
}

.v-toolbar__content {
  z-index: 6 !important;
}

.portal-bg {
  background-color: #f1f1f1;
}
.z-index-1 {
  z-index: 1;
}
.z-index-2 {
  z-index: 2;
}
.z-index-3 {
  z-index: 3;
}
.color-black {
  color: #000000 !important;
}
.vert-line {
  width: 1px;
  height: 100%;
}
.field-header {
  font-size: 1.4rem !important;
}
.app-font-color {
  color: #595959;
}
.grecaptcha-badge {
  z-index: 5;
}
