/* --- Variables --- */

@themeBlue: #0984e3;
@themeLightBlue: #37abf8;
@themeGreen: #32cfa9;
@brandGreen: #2ecc71;
@brandBlue: #f1f8fd;
@themeRed: hsl(12, 70%, 61%);
@themeGold: #f1c40f;
@themeOrange: #f39c12;
//@themeDarkNavy: #132436;
@themeDarkNavy: #212322;
@themeYellow: #ebdbac;

/* --- Styling Functions --- */

.theme-color(@value) {
  color: @value!important;
}
.theme-background(@value) {
  background-color: @value!important;
}
@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@400;600;700&family=Raleway:wght@300;400;500;600;700;900&display=swap');


/* --- Site-Wide Styling --- */
* {
  ::selection {
    background: @brandGreen !important; /* WebKit/Blink Browsers */
  }
  ::-moz-selection {
    background: @brandGreen !important; /* Gecko Browsers */
  }
  .green-btn {
    background-color: @brandGreen!important;
    .rounded-btn.v-btn;
  }
  .green-btn .v-btn__content {
    font-size: 1rem;
  }
  .rounded-card {
    border-radius: 23px !important;
  }
  .rounded-btn.v-btn {
    border-radius: 23px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .card-title {
    text-align: center;
    font-size: 1.9rem !important;
    color: #464646 !important;
    font-weight: 500;
    line-height: 3.5rem;
    letter-spacing: 0.0073529412em;
    font-family: 'Nunito', sans-serif !important;
  }
  .projected-savings {
    border: 2px solid @themeGreen;
    border-radius: 7px;
  }
}

/* --- Public Pages Styling --- */
.consumer-public {
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: 'Raleway', sans-serif;
  }

  span,
  p,
  div {
    font-family: 'Assistant', sans-serif;
    font-weight: 600;
  }

  .fade-common(@value) {
    transition-duration: @value;
    transition-property: height, opacity;
    overflow: hidden;
    transition-timing-function: ease;
  }
  .fade-enter-active {
    .fade-common(0.2s);
  }
  .fade-leave-active {
    .fade-common(0.1s);
  }

  .fade-enter,
  .fade-leave-active {
    opacity: 0;
  }
  ul {
    list-style-type: none;
  }

  .underline {
    background-color: black;
    height: 1px;
    width: 100%;
  }

  .list-item h3 {
    font-weight: 600;
  }
}

/* --- Consumer App Pages Styling --- */
.consumer-app {
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: 'Raleway', sans-serif !important;
  }

  span,
  p,
  div,
  label,
  .v-list-item__title {
    font-family: 'Assistant', sans-serif;
    font-weight: 600;
  }
  .portal-bg {
    background-color: #ffffff;
  }
  .container {
    max-width: 1400px;
  }
}
.w-100 {
  width: 100% !important;
}
