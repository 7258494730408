@import "@/assets/css/styles.less";

.max-height-limit {
  max-height: none !important;
}

.consumer-public {
  background-color: @brandGreen;
}

